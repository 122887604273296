import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {gql} from '../../../gql';
import {QueryResult, throwExpression} from '../../shared/utils';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {FormControl, FormGroup} from '@angular/forms';
import {VTableComponent, getRowClasses, getRowClassesInput} from '@vasio-nl/valow';
import { models } from 'src/app/shared/models';
import {FormHelperService} from '../../shared/services/form-helper.service';
import { PlacementOfferResolutionColumnComponent } from './placement-offer-solution-column/placement-offer-resolution-column.component';

const PLACEMENT_OFFER_OVERVIEW_QUERY = gql(/* GraphQL */ `
  query PlacementOfferOverviewComponent_placementOffer {
    placementOffers(first: 1000) {
      edges {
        node {
          id
          date
          resolution
          resolutionAt
          failureReason
          finalizedAt
          finalized
          location {
            ... on WegwijzerLocationType {
              id
              name
            }
          }
          newcomer {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`);

type QueryRecordType = QueryResult<typeof PLACEMENT_OFFER_OVERVIEW_QUERY>['placementOffers']['edges'][number]['node'];
type RecordType = Pick<QueryRecordType, 'id' | 'resolution' | 'date' | 'resolutionAt'> & {
  newcomerName: string;
  location: string;
  needsFinalized: boolean;
};

@Component({
  selector: 'app-placement-offer-overview',
  templateUrl: './placement-offer-overview.component.html',
  styleUrls: ['./placement-offer-overview.component.scss'],
})
export class PlacementOfferOverviewComponent implements OnInit, OnDestroy {
  @ViewChild('table', {static: true}) table: VTableComponent<any, any, any> | null = null;
  models = models;
  tableFields = this.formHelperService.getTableFields('PlacementOffer', ['date', 'resolution', 'resolutionAt', 'newcomerName', 'locationName']);

  // Used to fetch the data for the table but is not actually used as a column for display.
  additionalFields = this.formHelperService.getTableFields('PlacementOffer', ['needsFinalized']);

  filterFields: {field: string, description: string}[] = [
    {field: 'open', description: 'Open'},
    ...this.tableFields.filter(field => field.field !== 'locationName')
  ];
  filters = {search: '', orderBy: 'open'};

  getRowClasses: getRowClasses = ({data}) => {
    if (data.needsFinalized) {
      return ['needs-finalized'];
    }

    return [];
  };

  filterForm = new FormGroup({
    search: new FormControl<null | string>(null),
    orderBy: new FormControl<string>('open')
  });

  loadingRecords = true;
  searchForm = new FormGroup({
    search: new FormControl<null | string>(null),
  });
  subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    public formHelperService: FormHelperService
  ) {}

  ngOnInit(): void {
    const table = this.table ?? throwExpression('Table did not initialize');
    this.tableFields.find(field => field.field === 'resolution')!.customComponent = PlacementOfferResolutionColumnComponent;

    this.subscriptions.push(
      this.filterForm.controls.search.valueChanges.subscribe(search => {
        this.filters = {
          ...this.filters,
          search: search ?? ''
        };
      }),

      this.filterForm.controls.orderBy.valueChanges.subscribe(orderBy => {
        this.filters = {
          ...this.filters,
          orderBy: orderBy ?? 'date'
        };
      })
    );

    this.searchForm.valueChanges.subscribe(() => (this.loadingRecords = true));
  }

  openPlacementOffer(placementOffer: any): void {
    this.router.navigate(['/', 'plaatsing-voorstel', placementOffer.id]);
  }

  assertItemType(item: RecordType): RecordType {
    return item;
  }

  navigate(offer: RecordType): void {
    this.router.navigate(['/', 'plaatsing-voorstel', offer.id]);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
