<div class="card overview-card">
  <div class="controls">
    <div class="control">
            <p-button class="button" label="Nieuw voorstel" routerLink="/nieuw-plaatsing-voorstel"></p-button>
    </div>
    <div class="control filling">
      <span class="p-input-icon-left w-100">
          <i class="pi pi-search"></i>
          <input class="p-inputtext w-100" type="text" pInputText [formControl]="filterForm.controls.search" placeholder="Zoeken">
      </span>
    </div>

    <div class="control">
      <p-dropdown [options]="filterFields" [formControl]="filterForm.controls.orderBy" optionLabel="description" optionValue="field" styleClass="w-100" placeholder="Sorteer op"></p-dropdown>
    </div>
  </div>

  <app-vtable #table [formHelperService]="formHelperService" [modelInfo]="models.PlacementOffer" [rowHeight]="52" [fields]="tableFields" [filters]="filters" (onRowClick)="openPlacementOffer($event)" [getRowClasses]="getRowClasses" [additionalFields]="additionalFields"></app-vtable>
</div>
